import type { Meta } from './types/metaTypes';

export function useMeta() {
  const { $api } = useNuxtApp();

  const getMetaByUrl = async (url: string): Promise<Meta | null> => {
    try {
      const res = await $api<Meta>(`/seo-meta-data/get-by-url/${url}`, {
        method: 'GET'
      });
      return res;
    } catch (error) {
      console.error('Error fetching meta:', error);
      return null;
    }
  }

  return {
    getMetaByUrl
  }
}
